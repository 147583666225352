.captcha-whole-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f3ed;
}

.qr-page-main-div {
  width: 90%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.payment-below-payment-text {
  font-size: 1.8rem;
  font-weight: bold;
  color: #4e361f;
  margin-bottom: 10px;
}

.captcha-www-text {
  font-size: 1rem;
  color: #6c5138;
  margin-bottom: 20px;
}

.qrpage-scan-bank {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.qr-img-div,
.qrpage-bank-detail {
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.qr-page-accountdetail-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4e361f;
  margin-bottom: 10px;
}

.qrpage-account-texts {
  font-size: 1rem;
  color: #6c5138;
  margin-bottom: 5px;
}

.qr-home-button {
  display: inline-block;
  background-color: #4e361f;
  color: #fff;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-right: 5px;
}

.qr-home-button:hover {
  background-color: #3b2b1a;
}

.downloadpdf-btn {
  margin-left: 5px;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Set a height for proper alignment */
  margin-top: 1rem; /* Add some space between the text and the spinner */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #4e361f; /* Primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}