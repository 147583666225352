:root {
  --primary-color-text: #c58f4a;
}

.userform-affecting-span {
  font-size: xx-large;
  font-weight: 700;
  color: var(--primary-color-text);
  width: 46%;
  margin-left: 12%;
  line-height: 38px;
}

.userform-form-lable {
  font-size: large;
  color: gray;
  
}

.userform-input-checkbox {
  margin-right: 10px;
}

.userform-affect-field-div {
  box-shadow: 0px 0px 3px black;
  border-radius: 15px;
  padding: 3px 54px 3px 10px;
}

.userform-affecting-main-div {
  width: 75%;
}

.userform-bottom-users-field-main-div {
  background-color: #6c5138;
  margin-top: 30px;
  padding: 4% 13%;
}

.userform-bottom-user-field-label {
  padding-left: 12px;
  color: orange;
  margin-bottom: 5px;
}

.userform-bottom-user-field-input {
  padding: 0px 10px;
  border-radius: 17px;
  border: none;
  height: 32px;
  margin-bottom: 20px;
}

.userform-bottom-user-field-textarea {
  border-radius: 7px;
  margin-bottom: 20px;
}

.userform-submit-button {
  color: white;
  background-color: var(--primary-color-text);
  font-size: x-large;
  font-weight: 700;
  padding: 0px 20px;
  border-radius: 30px;
  border: 5px solid white;
}

.userform-submit-button-div {
  display: flex;
  justify-content: center;
  margin-top: -22px;
}

.userform-mobile-number-span {
  color: red;
  background-color: white;
  padding: 5px 10px;
  border-radius: 19px;
  font-weight: 400;
}

@media screen and (max-width: 425px) {
  .userform-affecting-span {
    font-size: x-large;
    width: 80%;
    margin-top: 30px;

    text-align: center;
  }
  .userform-form-lable {
    font-size: 15px;
  }

  .userform-work-or-career-field {
    margin-bottom: 18px;
  }
  .userform-bottom-user-field-label {
    font-size: 14px;
  }
  .userform-bottom-users-field-main-div {
    padding: 6% 13%;
  }
  .userform-submit-button-div {
    margin-top: -18px;
  }
  .userform-submit-button {
    font-size: large;
  }
}

/* New CSS */
/* Time Slot Buttons */
.time-slot-btn {
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Available Slot */
.time-slot-available {
  background-color: white;
  color: var(--primary-color-text);
  box-shadow: 0px 0px 3px gray;
}

/* Hover Effect for Available Slot */
.time-slot-available:hover {
  background-color: var(--primary-color-text);
  color: white;
}

/* Selected Slot */
.time-slot-selected {
  background-color: var(--primary-color-text);
  color: white;
  box-shadow: 0px 0px 5px white;
}

/* Unavailable Slot */
.time-slot-unavailable {
  background-color: gray;
  color: white;
  opacity: 0.6;
  cursor: not-allowed;
}

.userform-form-label {
    font-size: large;
    color: gray;
    padding-left: 12px;
  }

  .fit-content-input {
    width: fit-content;
  }