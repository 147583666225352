.summary-page-bg-div{
    background-image: url("./tmimages/bgcap.jpg");
    min-height: 100vh;
}

.summary-page-user-label-span{
    font-size: larger;
    font-weight: 600;
    color: #4e361f;
   


}

.summary-page-appointment-text{
    font-weight: 700;
    color: #4e361f;
    margin: 30px;
}

.summary-page-user-detail-span{
    background-color: white;
    padding: 5px 15px;
    font-size: large;
    border-radius: 25px;
    min-width: 15%;
    max-width: 60%;
    margin-bottom: 15px;
    min-height: 37px;
    max-height: 200px;
}

.summary-page-terms-div{
    width: 80%;
    font-size: larger;
    font-weight: 600;
    color: black;
    margin-bottom: 15px;
}

.summary-detail-mobile{
    display: none;
}

@media screen and (max-width:430px) {
    .summary-row-desktop {
    
        display: none;

    }
    .summary-detail-mobile{
        display: unset;
    }
    .summary-page-user-detail-span{
        max-width: 100%;
        padding: 2px 15px;
        font-size: medium;
        min-height: 28px;
    }

    .summary-mobile-view-fields{
        width: 80%;
    }

    .summary-page-user-label-span{
        margin-left: 10px;
        font-size: medium;
        margin-bottom: 3px;
    
    }
    .summary-page-terms-div{
        font-size: medium;
    }

 
}