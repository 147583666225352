:root {
    --primary-color-text: #c58f4a;
  }


.form-group{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.captcha-main-div{
    flex-direction: column;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.captcha-shadow-div{
    padding: 40px;
    box-shadow: 0px 0px 4px black;
    border-radius: 20px;
    background-color: white;
}

#reload_href{
    cursor: pointer;
    color: gray;
    padding: 0px 43px;
    font-weight: 600;
}


.captcha-whole-div{
    background-image: url("./tmimages/bgcap.jpg");
    height: 100vh;
}


#canv{
    width: 200px;
    height: 50px;
    margin-bottom: 10px;
}

.captcha-input{
    border-radius: 20px;
    border: none;
    box-shadow: 0px 0px 3px gray;
    padding-left: 10px;
    height: 40px;

}

.captcha-input:focus{
 
    outline: none;
}

.captcha-submit-button{
    border: none;
    color: white;
    box-shadow: 0px 0px 3px gray;
    border-radius: 22px;
    padding: 5px 15px;
    font-weight: 600;
    background-color: var(--primary-color-text);
    font-size: larger
}

.Captcha-enter-captcha-text{
    font-size: x-large;
    letter-spacing: 1px;
    font-weight: 600;
    color: #4e361f;
}

.captcha-www-text{
    font-size: xx-large;
    letter-spacing: 1px;
    font-weight: 600;
    color: #4e361f;

}

@media screen and (max-width:425px) {
    .Captcha-enter-captcha-text{
        font-size: large;
    }
    .captcha-www-text{
        font-size: larger;
        text-align: center;
        width: 80%;
    
    }
    .captcha-shadow-div {
        padding: 25px;

    }
    .captcha-submit-button{
        font-size: large;
    }
   
}