.feepage-fee-agree-text{
    font-size: xx-large;
    font-weight: 700;
}

.feepage-main-content-div{
    width: 74%;
}

.feepage-counsellor-text{
    font-size: xx-large;
    color: var(--primary-color-text);
}

.feepage-1st-ul-div{
    background-color:#6c5138;
    border-radius: 30px;
    padding: 20px 40px 20px 20px;
}

.feepage-1st-ul{
    font-size: large;
    letter-spacing: 1px;
    font-weight: 500;
    color: white;
}

.feepage-1st-ul li{
    margin-bottom: 10px;
}

.feepage-bottom-paras{
    font-size: large;
    letter-spacing: 1px;
    font-weight: 500;
    color: #353333;
}
.feepage-bottom-para-span{
    font-weight: 800;
    font-size: 20px;

}
.feepage-bottom-input-checkbox{
    width: 23px;
    box-shadow: 2px 2px 5px black;
    height: 22px;
    margin-right: 10px;
}


@media screen and (max-width: 425px) {
    .feepage-fee-agree-text {
        font-size: x-large;
        text-align: center;
        margin-top: 35px;
        margin-bottom: 0px;
      }

      .feepage-main-content-div {
        width: 80%;
    }
    .feepage-counsellor-text {
        font-size: x-large;
        text-align: center;
    }
    .feepage-1st-ul{
        font-size: 14px;
    }
    .feepage-bottom-paras{
        font-size: 14px;
    }
    .feepage-bottom-para-span{
        font-size: 15px;
    }
    .feepage-bottom-input-checkbox {
        width: 18px;
        box-shadow: 0px 0px 1px black;
        height: 14px;
    }

    .feepage-next-button-div{
        justify-content: center !important;
    }

}