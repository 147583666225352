:root {
  --primary-color-text: #c58f4a;
}
.HomePage-main-img-div {
  width: 100%;
  height: 100vh;
}
.homepage-main-img {
  width: 100%;
  height: 106vh;
  position: absolute;
}

.Homepage-main-lalitha-text {
  position: absolute;
  text-shadow: 0px 3px 3px black;

  top: 27%;
  left: 15%;
  color: rgb(250, 246, 246);
  font-size: 55px;

  font-weight: 700;
  text-shadow: 0px 1px 3px black;
  letter-spacing: 1px;
}

.Homepage-main-partner-text {
  position: absolute;
  top: 39%;
  left: 15%;
  font-size: 30px;

  /* font-weight: 500; */
  color: #411818;
  text-shadow: -2px -2px #e7e7c9, 2px -2px #e7e7c9, -2px 2px #e7e7c9,
    2px 2px #e7e7c9;
  font-weight: 500;
  /* -webkit-text-stroke: 0.5px rgb(229, 207, 158); */
  letter-spacing: 1.5px;
}

.homepage-main-book-button {
  position: absolute;
  border: none;
  color: white;
  background-color: black;
  font-weight: 700;
  padding: 13px 18px;
  border-radius: 25px;
  top: 53%;
  left: 14%;
  letter-spacing: 1px;
}

.homepage-video-sun-image {
  position: absolute;
  top: 50.5%;
  left: 27%;
  width: 80px;
  height: 80px;
}

.homepage-main-watch-text {
  position: absolute;
  top: 55%;
  left: 34%;
  font-size: small;
  font-weight: 700;
  color: black;
  letter-spacing: 1px;
}

.homepage-logo {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.homepage-logo:hover {
  cursor: pointer;
}

.homepage-main-logo-div {
  background-color: black;
  height: 28%;
  width: 13%;
  position: absolute;
  top: 0;
  left: 15%;
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 0px 0px 95px 95px;
}

.homepage-main-tend-text {
  position: absolute;
  top: 6%;
  left: 29%;
}

.homepage-tend-text {
  font-weight: 700;
  font-size: x-large;
}

.homepage-counselling-bg {
  background-image: url("./tmimages/counsellingbg.jpg");
  padding-top: 300px;
  margin-bottom: -300px;
  padding-bottom: 320px;
  background-size: cover;
  margin-top: -300px;
  position: relative;
  z-index: 0;
}

.homepage-main-counselling-text {
  position: absolute;
  font-size: medium;
}
.homepage-dr-first-lalitha-span {
  font-weight: 800;
  font-size: larger;
  color: var(--primary-color-text);
}

.homepage-banner-img-below-content {
  margin-top: -3%;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Initially moved to the left */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Transition effect */
}

.homepage-banner-img-below-content.fade-in {
  opacity: 1; /* Visible when scrolled into view */
  transform: translateX(0); /* Back to original position */
}

.hompage-banner-below-col1 {
  z-index: 2;
  text-align: end;
  height: fit-content;
}
.hompage-banner-below-col2 {
  z-index: 2;
}
.homepage-banner-below-col-span {
  font-weight: 500;
  font-size: x-large;
  margin-top: -6px;
}

.homepage-dr-lalitha-name-span {
  color: #6a4b25;
}

.homepage-banner-below-col-gold-span {
  color: #c58f4a;
  font-size: xx-large;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -10px;
}

.homepage-sun-hand-img {
  position: absolute;
  width: 52%;
  height: 216%;
  top: -6%;
  right: -20%;
}

.homepage-half-sun-left {
  width: 71%;
}

.homepage-1stcol-lady-img {
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Initially moved to the left */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Transition effect */
}

.homepage-1stcol-lady-img.fade-in {
  opacity: 1; /* Visible when scrolled into view */
  transform: translateX(0); /* Back to original position */
}

.homepage-lady-img {
  width: 100%;
  margin-left: 80px;
}

.hompage-right-col-lady-img-text {
  margin-top: 6%;
  opacity: 0; /* Initially hidden */
  transform: translateX(50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
}
.hompage-right-col-lady-img-text.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Back to original position */
}

.homepage-right-col-lady-about {
  font-weight: 500;
  font-size: 20px;
}

.homepage-right-col-lady-name {
  color: var(--primary-color-text);
  font-weight: 700;
  font-size: xx-large;
}
.homepage-right-col-lady-content {
  font-weight: 500;
  font-size: 20px;
  width: 105%;
  letter-spacing: 1px;
}

.homepage-right-col-lady-sign {
  width: 25%;
}

.homepage-right-col-lady-signname {
  color: var(--primary-color-text);
}
.homepage-years-div {
  position: absolute;
  bottom: 24%;
  z-index: 5;
  right: 11%;
  background-color: var(--primary-color-text);
  padding: 15px 15px;
  border-radius: 50%;
  border: 8px solid white;
  box-shadow: 0px 1px 7px black;
}

.homepage-5years-text-span {
  color: white;
  font-size: xx-large;
  font-weight: 800;
}

.homepage-years-text-span {
  margin-top: -10px;
  font-weight: 700;
  font-size: x-large;
}

.Homepage-1st-div-bg {
  background-image: url("./tmimages/bg1resize3.png");
  background-size: cover;
  padding-top: 16%;
  margin-top: -19%;
  filter: drop-shadow(0px 0px 4px gray);
  position: relative;
  z-index: 3;
}
.homepage-navigating-life-col {
  justify-content: end;
}

.homepage-navigating-life-div-span {
  font-size: xx-large;
  font-weight: 700;
  letter-spacing: 1px;
  width: 70%;
  margin-bottom: 15px;
  line-height: 38px;
}

.homepage-navigating-life-div {
  width: 70%;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-navigating-life-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-navigating-life-yoga-img {
  width: 79%;
  margin-left: -48px;
  opacity: 0; /* Initially hidden */
  transform: translateX(50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
}

.homepage-navigating-life-yoga-img.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Back to original position */
}

.homepage-navigating-life-yoga-img:hover {
  filter: drop-shadow(0px 0px 10px black);
  transform: scale(1.05);
}

.homepage-navigating-life-yoga-img:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.homepage-navigating-life-content-span {
  color: white;
  font-size: large;
  font-weight: 400;
  letter-spacing: 1px;
}

.homepage-dr-lalitha-span {
  font-weight: 800;
  font-size: larger;
}
.homepage-navigating-life-lefe2-img {
  width: 79%;
  margin-right: -25px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.homepage-navigating-life-lefe2-img.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-navigating-life-lefe2-img:hover {
  filter: drop-shadow(0px 0px 10px black);
  transform: scale(1.05);
}

.homepage-navigating-life-lefe2-img:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.homepage-navigating-life-life2-div {
  display: flex;
  justify-content: end;
  overflow: hidden;
}

.homepage-navigating-life2-div {
  width: 70%;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  transform: translateX(50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-navigating-life2-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-navigating-life2-col {
  justify-content: start;
}

.homepage-navigating-life2-row {
  margin-top: -7%;
}
.homepage-navigating-life2-div-span {
  margin-bottom: 15px;
  font-size: xx-large;
  font-weight: 700;
  letter-spacing: 1px;
  width: 90%;
  line-height: 38px;
}

.homepage-counselling-service-main-div {
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.homepage-counselling-service-main-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0);
}

.homepage-counselling-carousel-main-div {
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Initially moved to the right */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-counselling-carousel-main-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0);
}

.homepage-counselling-text-span {
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 15px;
}

.homepage-certain-text-span {
  width: 70%;
  text-align: center;
  font-size: small;
  letter-spacing: 1px;
  font-weight: 500;
}

/* .homepage-service-offered{
    width: 85%;
    display: flex;
    justify-content: center;
margin-top: 30px;
}

.homepage-couple-img{
      border-radius: 30px 30px 0px 0px;
}

.hompage-counselling-cards{
    position: relative;
}

.homepage-oct-img{
    position: absolute;
  
    width: 25%;
    height: 20%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 26.5%;
    border-radius: 34px 34px 0px 0px;
    background-color: var(--primary-color-text);
}

.homepage-last-three-oct-image{
    position: absolute;
 
    width: 25%;
    height: 20%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 26.5%;
    border-radius: 34px 34px 0px 0px;
    background-color: var(--primary-color-text);
}

.hompage-couples-counselling-text-span{
    text-align: center;
    margin-top: 22px;
    margin-bottom: 40px;
    color: var(--primary-color-text);
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0px 20px;
   
}

.hompage-couples-counselling-text-span-last-three{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 28px;
    color: var(--primary-color-text);
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0px 20px;
}

.homepage-get-started-text-span{
    color: white;
    background-color: black;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 20px;
    width: fit-content;
    padding: 5px 15px;
    font-size: medium;
    position: absolute;
    bottom: -4%;
}

.homepage-get-started-text-span:hover{
    cursor: pointer;
    background-color: var(--primary-color-text);
}


.homepage-counservice-1st-col{
    margin-bottom: 65px;
    border-radius: 30px;;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 45px;
    background-color: white;
    opacity: 0; 
    transform: translateY(50px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}

.homepage-counservice-1st-col.fade-in{
    opacity: 1; 
  transform: translateX(0); 
}

.homepage-counservice-1st-col:hover{
    
   transform: scale(1.05);
 }

.homepage-counservice-2col{
    margin-bottom: 65px;
    border-radius: 22px;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 45px;
    background-color: white;
    opacity: 0;
    transform: translateY(50px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}
.homepage-counservice-2col.fade-in{
    opacity: 1; 
  transform: translateX(0); 
}

.homepage-counservice-2col:hover{
    
   transform: scale(1.05);
 }

.homepage-counservice-3col{
    margin-bottom: 65px;
    border-radius: 30px;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 0px;
    background-color: white;
    opacity: 0;
    transform: translateY(50px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}
.homepage-counservice-3col.fade-in{
    opacity: 1; 
  transform: translateX(0); 
}

.homepage-counservice-3col:hover{
    
   transform: scale(1.05);
 }

.homepage-counservice-4col{
    margin-bottom: 65px;
    border-radius: 22px;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 45px;
    background-color: white;
    opacity: 0; 
    transform: translateY(50px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}

.homepage-counservice-4col.fade-in{
    opacity: 1;
  transform: translateX(0);
}
.homepage-counservice-4col:hover{
    
   transform: scale(1.05);
 }

.homepage-counservice-5col{
    margin-bottom: 65px;
    border-radius: 22px;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 45px;
    background-color: white;
    opacity: 0; 
    transform: translateY(50px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}

.homepage-counservice-5col.fade-in{
    opacity: 1; 
  transform: translateX(0); 
}
.homepage-counservice-5col:hover{
   transform: scale(1.05);
 }

.homepage-counservice-6col{
    margin-bottom: 65px;
    border-radius: 22px;
    box-shadow: 0px 0px 3px gray;
    padding: 0px;
    margin-right: 0px;
    background-color: white;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}

.homepage-counservice-6col.fade-in{
    opacity: 1; 
  transform: translateX(0); 
}
.homepage-counservice-6col:hover{
    
   transform: scale(1.05);
 } */
.homepage-service-offered {
  width: 85%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  overflow: hidden; /* Hide the overflow to show only 3 items at a time */
}
.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 77px;
  align-items: center;
}

.slider-content {
  width: 66%;
  display: flex;
  transition: transform 0.5s ease; /* Smooth transition when sliding */
  flex-direction: row;
  gap: 60px;
}

.homepage-couple-img {
  width: 100%;
  border-radius: 30px 30px 0px 0px;
}

.hompage-counselling-cards {
  position: relative;
  background-color: white;
  border-radius: 30px;
  background-color: white;
  border-radius: 30px;
  height: 100%;
  width: 85%;
  margin-bottom: 60px;
}

.homepage-oct-img,
.homepage-last-three-oct-image {
  position: absolute;
  width: 25%;
  height: 20%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 31%;

  border-radius: 34px 34px 0px 0px;
  background-color: var(--primary-color-text);
}

.hompage-couples-counselling-text-span,
.hompage-couples-counselling-text-span-last-three {
  text-align: center;

  color: var(--primary-color-text);
  font-weight: 700;
  letter-spacing: 1px;
  height: 79px;
  /* vertical-align: middle; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.homepage-get-started-text-span {
  color: white;
  background-color: black;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 20px;
  width: fit-content;
  padding: 5px 15px;
  font-size: medium;
  margin-bottom: -18px;
}

.homepage-get-started-text-span:hover {
  cursor: pointer;
  background-color: var(--primary-color-text);
}

.homepage-counservice-1st-col,
.homepage-counservice-2col,
.homepage-counservice-3col,
.homepage-counservice-4col,
.homepage-counservice-5col,
.homepage-counservice-6col {
  margin-bottom: 65px;
  border-radius: 22px;
  box-shadow: 0px 0px 3px gray;
  padding: 0px;
  background-color: white;
  /* opacity: 0; */
  /* transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; */
}

/* .homepage-counservice-1st-col.fade-in,
.homepage-counservice-2col.fade-in,
.homepage-counservice-3col.fade-in,
.homepage-counservice-4col.fade-in,
.homepage-counservice-5col.fade-in,
.homepage-counservice-6col.fade-in {
    opacity: 1;
    transform: translateX(0);
} */

/* .homepage-counservice-1st-col:hover,
.homepage-counservice-2col:hover,
.homepage-counservice-3col:hover,
.homepage-counservice-4col:hover,
.homepage-counservice-5col:hover,
.homepage-counservice-6col:hover {
    transform: scale(1.05);
}

.slider-button {
  
    border: none;
    padding: 8px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;

    margin: 0 10px;
    height: fit-content;
}
.homepage-slider-left-right-icons{
    width: 25px ;
    height: 25px;
}

.slider-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


 /* Life row 2 */
.homepage-1st-col-counselling-service {
  display: flex !important;
  justify-content: center !important;
}

.homepage-1st-col-counselling-service:hover {
  transition: opacity 0.5s ease-out, transform 0.6s ease-out;
  transform: scale(1.05) !important;
}

.Homepage-1st-div-bg3 {
  background-image: url("./tmimages/bg2resize1.png");
  background-size: cover;
  padding-top: 3%;
  filter: drop-shadow(0px 0px 4px gray);
}

.homepage-life3-row2-col1-below-counselling {
  margin-top: -7%;
  justify-content: end;
}

.homepage-life3-row2-div {
  width: 78%;
  justify-content: center;
  margin-top: 25px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-life3-row2-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-life3-row2-img-div {
  display: flex;
  justify-content: end;
  overflow: hidden;
}

.homepage-life3-row2-misunderstanding-img {
  width: 79%;
  margin-left: -48px;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-life3-row2-misunderstanding-img.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Back to original position */
}

.homepage-life3-row2-misunderstanding-img:hover {
  filter: drop-shadow(0px 0px 10px black);
  transform: scale(1.05);
}

.homepage-life3-row2-misunderstanding-img:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.homepage-life3-row-family-img {
  width: 79%;
  margin-right: -25px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.homepage-life3-row-family-img.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-life3-row-family-img:hover {
  filter: drop-shadow(0px 0px 10px black);
  transform: scale(1.05);
}

.homepage-life3-row-family-img:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.homepage-life3-row4-family-div {
  width: 70%;
  justify-content: center;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.homepage-life3-row4-family-div.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0);
}

.homepage-life3-row2-div-span {
  font-size: xx-large;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 15px;
  line-height: 38px;
}

.react-multiple-carousel__arrow--left {
  left: 11%;
  top: 37%;
}
.react-multiple-carousel__arrow--right {
  right: 12%;
  top: 37%;
}

/* Bottom tend-to-mend */

.homepage-bottom-tend-to-mend-1st-col {
  display: flex;
  justify-content: end;
}

.homepage-bottom-tend-to-mend-logo-div {
  background-color: black;
  width: 62%;
  padding: 120px 0px 40px 0px;
  margin-top: -68px;
  z-index: -1;
  border-radius: 0px 0px 189px 189px;
  margin-right: 23px;
}

.homepage-bottom-benefits-text-span {
  color: white;
  font-weight: 700;

  font-size: 15px;
  text-align: center;
}

.homepage-bottom-tend-to-mend-text {
  font-size: 33px;
  color: var(--primary-color-text);
  font-weight: 700;
  text-align: center;
  margin-top: -5px;
}
.homepage-bottom-benefitsofcounselling-col2 {
  position: relative;
}

.homepage-bottom-tend-to-mend-col2 {
  margin-top: 23px;
}
.homepage-bottom-lalitha-text {
  font-size: 45px;
  font-weight: 700;
  color: var(--primary-color-text);
}

.homepage-bottom-counselling-text {
  font-size: 28px;

  letter-spacing: 1px;
  font-weight: 700;
}

.homepage-bottom-email-logo {
  color: white;
  background-color: black;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 5px;
}

.homepage-bottom-mail-text {
  font-size: 30px;
  color: var(--primary-color-text);
  letter-spacing: 1px;
  font-weight: 700;
  margin-left: 12px;
}
.homepage-bottom-email-div {
  margin-top: 15px;
}

.homepage-bottom-timing-div {
  background-color: var(--primary-color-text);
  width: 63%;
  height: fit-content;
  border-radius: 70px;
  margin-top: 20px;
  padding: 8px;
}

.homepage-bottom-timing-text {
  color: white;
  font-weight: 700;
  font-size: 20px;
}

.homepage-bottom-montosat-text {
  font-size: x-large;
  font-weight: 700;
  color: black;
}

.hompage-bottom-timing-number {
  font-size: x-large;
  font-weight: 700;
  color: white;
}
.homepage-timing-col-2 {
  padding: 0px 15px;
}
.homepage-bottom-timing-img {
  width: 21%;
  border-radius: 50%;
  background-color: white;
  padding: 5px;
}

.homepage-bottom-sun1-image {
  width: 74%;
  position: absolute;
  top: 137px;
  right: -87px;
}

.homepage-bottom-modes-of-counselling-text {
  color: white;
  font-weight: 700;
  font-size: 20px;
  background-color: black;
  padding: 5px 25px;
  border-radius: 20px;
  margin-top: 40px;
}

.homepage-bottom-arrow-img {
  margin-top: -7px;
  width: 50%;
}

.homepage-bottom-video-div {
  background-color: var(--primary-color-text);
  display: flex;
  align-items: center;
  border-radius: 44px;
  padding: 5px;
  margin-right: -35px;
}

.homepage-bottom-video-icon {
  width: 30px;
  height: 28px;
}

.homepage-bottom-video-call-text {
  color: white;
  font-size: 23px;
  font-weight: 700;
  padding: 15px;
  letter-spacing: 1px;
}

.homepage-bottom-video-icon-span {
  padding: 17px;
  border-radius: 50%;
  background-color: white;
}

.homepage-bottom-voice-div {
  background-color: var(--primary-color-text);
  display: flex;
  align-items: center;
  border-radius: 44px;
  padding: 5px;
}
.homepage-bottom-chat-div {
  background-color: var(--primary-color-text);
  display: flex;
  align-items: center;
  border-radius: 44px;
  padding: 5px;
  margin-left: -35px;
}

.homepage-bottom-chat-text {
  color: white;
  font-size: 23px;
  font-weight: 700;
  padding: 15px 45px;
  letter-spacing: 1px;
}
.homepage-bottom-social-icons {
  margin-top: 50px;
  margin-bottom: -6px;
}

.homepage-bottom-facebook-icon {
  height: 16px;
  color: white;
  width: 12px;
}
.homepage-bottom-facebook-icon-span {
  background-color: #2626be;
  padding: 4px 10px;
  border: 3px solid white;
  border-radius: 5px;
}

.homepage-bottom-twitter-icon {
  height: 16px;
  color: white;
  width: 16px;
}
.homepage-bottom-twitter-icon-span {
  background-color: black;
  padding: 4px 7px;
  border: 3px solid white;
  border-radius: 5px;
}
.homepage-bottom-google-icon {
  height: 32px;
  color: white;
  width: 24px;
}
.homepage-bottom-google-icon-span {
  background-color: #c02b2b;
  padding: 0px 4px;
  border: 3px solid white;
  border-radius: 5px;
}
.homepage-bottom-linkedin-icon {
  height: 19px;
  color: white;
  width: 16px;
}
.homepage-bottom-linkedin-icon-span {
  background-color: #2679ea;

  padding: 0px 8px;
  border: 3px solid white;
  border-radius: 5px;
}
.homepage-bottom-pin-icon {
  height: 19px;
  color: white;
  width: 16px;
}
.homepage-bottom-pin-icon-span {
  background-color: #df2727;
  padding: 2px 7px;
  border: 3px solid white;
  border-radius: 5px;
}
.homepage-bottom-share-icon {
  height: 26px;
  color: white;
  width: 19px;
}
.homepage-bottom-share-icon-span {
  background-color: #59ba2e;
  padding: 0px 7px;
  border: 3px solid white;
  border-radius: 5px;
}

.homepage-bottom-privacy-tex-para {
  text-align: center;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 19px;
  background-color: var(--primary-color-text);
  letter-spacing: 1px;
  padding: 13px;
  color: black;
}

.homepage-bottom-copyright-row {
  background-color: black;
  padding: 5px;
}

.homepage-bottom-copy-rights-para-left {
  text-align: center;
  margin-bottom: 0px;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.homepage-bottom-copy-rights-para-right {
  text-align: center;
  margin-bottom: 0px;
  color: var(--primary-color-text);
  font-size: 15px;
  font-weight: 600;
}

.homepage-bottom-mobile-arrrow {
  display: none;
}

@media screen and (max-width: 1024px) {
  .homepage-bottom-timing-div {
    width: 80%;
  }
}

/* Mobile Responsive */

@media screen and (max-width: 768px) {
  .homepage-main-logo-div {
    width: 15%;
    height: 12%;
    z-index: 3;
    padding-bottom: 10px;
  }
  .homepage-main-tend-text {
    position: absolute;
    top: 0%;
    left: 0%;
    padding-left: 140px;

    width: 100%;
    height: 6%;
    background-color: white;
    z-index: 0;
    padding-top: 5px;
  }
  .homepage-tend-text {
    font-weight: 700;
    font-size: small;
  }
  .homepage-main-counselling-text {
    font-size: x-small;
  }
  .homepage-main-img {
    width: 100%;
    height: 37vh;
    position: absolute;
    top: 7%;
  }
  .Homepage-main-lalitha-text {
    font-size: 20px;
    top: 17%;
  }
  .Homepage-main-partner-text {
    top: 21%;
    font-size: 10px;
    text-shadow: 0px 0px;
  }
  .homepage-main-book-button {
    padding: 3px 12px;
    top: 28%;
    font-size: 7px;
  }

  .homepage-main-watch-text {
    top: 28%;
    font-size: 7px;
    left: 45%;
  }
  .homepage-video-sun-image {
    top: 26.5%;
    left: 35%;
    width: 38px;
    height: 39px;
  }
  .HomePage-main-img-div {
    width: 100%;
    height: 59vh;
  }

  .homepage-sun-hand-img {
    display: none;
  }
  .homepage-half-sun-left {
    transform: rotate(90deg);
  }

  .hompage-banner-below-col1 {
    display: flex;
    justify-content: center;
    margin-top: -130px;
  }
  .homepage-half-sun-left {
    width: 18%;
  }
  .hompage-banner-below-col2 {
    z-index: 2;
    align-items: center;
    text-align: center;
    margin-top: -15px;
  }
  .homepage-banner-below-col-span {
    font-size: medium;
    width: 80%;
    margin-bottom: 7px;
  }
  .homepage-banner-below-col-gold-span {
    font-size: 17px;
    width: 71%;
    margin-bottom: 10px;
  }
  .homepage-lady-img {
    margin-left: 17px;
  }
  .homepage-5years-text-span {
    font-size: x-large;
  }
  .homepage-years-text-span {
    font-size: large;
  }
  .hompage-right-col-lady-img-text {
    text-align: center;
  }
  .homepage-about-lady-content-div {
    align-items: center;
  }
  .homepage-right-col-lady-about {
    font-size: 14px;
  }
  .homepage-right-col-lady-name {
    font-size: large;
  }
  .homepage-right-col-lady-content {
    width: 95%;
    font-size: 14px;
  }
  .homepage-right-col-lady-sign {
    width: 34%;
  }
  .homepage-right-col-lady-signname {
    font-size: x-small;
  }
  .homepage-navigating-life-col {
    order: 2;
    margin-top: -25px;
    justify-content: center;
    text-align: center;
  }
  .homepage-navigating-life-right-div {
    order: 1;
    display: flex;
    justify-content: center;
  }
  .homepage-navigating-life-yoga-img {
    margin-left: 0px;
    width: 90%;
  }

  .homepage-navigating-life-div-span {
    font-size: x-large;
    width: 70%;

    font-weight: 800;
  }
  .homepage-navigating-life-content-span {
    font-size: smaller;
    font-weight: 400;
  }
  .homepage-navigating-life-div {
    width: 81%;
    /* justify-content: center; */
    align-items: center;
  }
  .homepage-navigating-life-life2-div {
    justify-content: center;
  }
  .homepage-navigating-life-lefe2-img {
    width: 90%;
  }
  .homepage-navigating-life2-col {
    justify-content: center;
    text-align: center;
    margin-top: -25px;
  }
  .homepage-navigating-life2-div-span {
    font-size: x-large;
    font-weight: 800;
  }
  .homepage-navigating-life2-div {
    width: 85%;
    align-items: center;
  }
  .homepage-counservice-1st-col {
    margin-right: 21px;
    width: 44%;
  }
  .homepage-counservice-2col {
    width: 44%;
    margin-right: 0px;
  }
  .homepage-counservice-3col {
    width: 44%;
    margin-right: 19px;
    /* margin-left: 12px; */
  }
  .homepage-counservice-4col {
    width: 44%;
    margin-right: 0px;
  }
  .homepage-counservice-5col {
    margin-right: 21px;
    width: 44%;
  }
  .homepage-counservice-6col {
    width: 44%;
    margin-right: 0px;
  }

  .homepage-service-offered {
    display: flex;
    justify-content: center;
    margin: 30px 0px 0px 0px;
  }
  .hompage-couples-counselling-text-span {
    font-size: x-small;
    margin-top: 10px;
    margin-bottom: 27px;
  }
  .homepage-couple-img {
    border-radius: 24px 24px 0px 0px;
  }

  .homepage-get-started-text-span {
    font-size: x-small;
  }
  .homepage-oct-img {
    height: 22%;
    bottom: 28.5%;
    width: 28%;
    border-radius: 45px 45px 0px 0px;
  }
  .homepage-last-three-oct-image {
    height: 22%;
    bottom: 30.7%;
    width: 28%;
    border-radius: 45px 45px 0px 0px;
  }
  .hompage-couples-counselling-text-span-last-three {
    font-size: x-small;
    margin-bottom: 23px;
    margin-top: 7px;
  }

  .homepage-counselling-text-span {
    font-size: large;
    margin-top: 15px;
    font-weight: 700;
  }
  .homepage-certain-text-span {
    font-size: x-small;
    width: 90%;
  }

  .homepage-life3-row2-img-div {
    justify-content: center;
  }

  .homepage-life3-row2-misunderstanding-img {
    margin-left: 0px;
    width: 90%;
  }

  .homepage-life3-row-family-img {
    width: 90%;
  }

  .homepage-life3-row4-family-div {
    width: 85%;
    align-items: center;
  }

  .homepage-life3-row2-div-span {
    font-size: x-large;
    width: 100%;
    font-weight: 800;
  }

  .homepage-life3-row2-col1-below-counselling {
    order: 2;
    margin-top: -25px;
    justify-content: center;
    text-align: center;
  }
  .homepage-life3-row2-div {
    margin-top: 0px;
  }

  .react-multi-carousel-item {
    width: 350px !important;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 700px !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 9% !important;
  }
}

@media screen and (max-width: 500px) {
  .mobile-item-width {
    width: 370px !important;
  }
  .react-multi-carousel-list {
    width: 370px !important;
    padding-left: 0px;
  }

  .Homepage-1st-div-bg {
    background-image: url("./tmimages/M-bg.png");
    background-size: cover;
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .homepage-navigating-life-div-span {
    font-size: x-large;
    width: 78%;
  }
  .homepage-counselling-carousel-main-div {
    margin-top: 15px !important;
  }
  .hompage-couples-counselling-text-span-last-three {
    font-size: larger;
    margin-bottom: 14px;
  }
  .hompage-couples-counselling-text-span {
    font-size: larger;
    margin-bottom: 11px;
  }
  .homepage-oct-img {
    bottom: 30.7%;
  }

  .Homepage-1st-div-bg3 {
    background-image: url("./tmimages/M-bg2.png");
    padding-bottom: 155px;
  }
  .homepage-bottom-tend-to-mend-1st-col {
    display: flex;

    justify-content: center;
  }
  .homepage-bottom-tend-to-mend-logo-div {
    margin-right: 0px;
  }
  .homepage-bottom-tend-to-mend-text {
    font-size: 30px;
  }
  .homepage-bottom-benefits-text-span {
    font-size: 13px;
  }
  .homepage-bottom-tend-to-mend-logo-div {
    width: 58%;
  }
  .homepage-bottom-lalitha-text {
    font-size: 32px;
    text-align: center;
  }

  .homepage-get-started-text-span {
    font-size: unset;
  }
  .homepage-bottom-counselling-text {
    font-size: 15px;
    text-align: center;
  }

  .homepage-counselling-bg {
    padding-bottom: 280px;
  }

  .homepage-bottom-mail-text {
    font-size: 20px;
  }

  .homepage-bottom-email-logo {
    width: 30px;
    height: 30px;
  }
  .homepage-bottom-email-div {
    justify-content: center;
    align-items: center;
  }
  .homepage-bottom-sun1-image {
    display: none;
  }
  .homepage-bottom-timing-text {
    font-size: 15px;
  }
  .homepage-bottom-montosat-text {
    font-size: 17px;
  }
  .hompage-bottom-timing-number {
    font-size: 17px;
  }
  .homepage-bottom-timing-div {
    width: 91%;
  }
  .homepage-bottom-timing-whole-div {
    display: flex;
    justify-content: center;
  }
  .homepage-bottom-video-main-div {
    justify-content: center !important;
  }
  .homepage-bottom-video-div {
    margin-right: 0px;
  }

  .homepage-bottom-chat-main-div {
    justify-content: center !important;
  }
  .homepage-bottom-chat-div {
    margin-left: 0px;
  }
  .homepage-bottom-video-call-text {
    font-size: 20px;
  }
  .homepage-bottom-chat-text {
    font-size: 20px;
    padding: 15px 70px 15px 13px;
  }
  .homepage-bottom-arrow-img {
    display: none;
  }

  .homepage-bottom-copy-rights-para-left {
    font-size: 14px;
  }
  .homepage-bottom-mobile-arrrow {
    display: block;
    height: 70px;
    width: 67px;
    margin-top: -19px;
    margin-bottom: -15px;
    color: black;
  }
  .homepage-bottom-privacy-tex-para {
    font-size: 12px;
  }
  .homepage-bottom-copy-rights-para-right {
    font-size: 12px;
  }
}

@media screen and (max-width: 430px) {
  .Homepage-1st-div-bg3 {
    padding-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 912px !important;
    position: unset;
  }
  .react-multi-carousel-item {
    width: 300px !important; /* Adjust the width as needed */
  }
}
