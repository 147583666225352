:root {
  --primary-color-text: #c58f4a;
}

.consentform-main-img {
  width: 100%;
  height: 60vh;
  position: absolute;
}
.consentform-main-img-div {
  width: 100%;
  height: 65vh;
}

.consentform-main-logo-div {
  background-color: black;
  height: 27%;
  width: 13%;
  position: absolute;
  top: 0;
  left: 12%;
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 0px 0px 100px 100px;
}

.consentform-main-lalitha-text {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  top: 20%;
  left: 27%;
  color: #6a4b25;
}
.consentform-mobile-span {
  color: white;
  font-size: 17px;
}
.consentform-mail-span {
  color: white;
  font-size: 17px;
}
.consentform-insta-para {
  color: white;
  font-size: 17px;
}

.consentform-main-counselling-text {
  margin-bottom: 0px;
  position: absolute;
  top: 26%;
  left: 27%;
  color: #6a4b25;
  font-size: 20px;
  font-weight: 500;
}
.consentform-main-marital-text {
  margin-bottom: 0px;
  position: absolute;
  top: 30%;
  left: 27%;
  color: #6a4b25;
  font-size: 20px;
  font-weight: 500;
}

.consentform-phone-email-div {
  position: absolute;
  top: 36%;
  left: 27%;
}

.consent-main-phone-icon {
  width: 28px;
  height: 28px;
  background-color: black;
  padding: 5px;
  border-radius: 50%;
  color: white;
  margin-right: 4px;
}
.consentform-insta-para {
  margin-bottom: 0px;
  position: absolute;
  top: 43%;
  left: 27%;
}

.consent-tend-text {
  font-weight: 700;
  font-size: xx-large;
  top: 3%;
  left: 27%;
  position: absolute;
}

.consent-main-counselling-text {
  font-size: larger;
  position: absolute;
  top: 9%;
  left: 27%;
}

.consentform-lady-img {
  position: absolute;
  width: 38%;
  top: 11%;
  right: 4%;
}

/* below banner */

.consent-form-informed-consent-div {
  width: 74%;
  margin-top: 30px;
  padding-left: 44px;
  position: relative;
}

.consent-form-informed-consent-text {
  font-size: xx-large;
  font-weight: 700;
  color: var(--primary-color-text);
}

.consent-form-iam-text {
  margin-top: 10px;
  font-size: large;
  letter-spacing: 1px;
  font-weight: 500;
}

.consentform-sunhand-img {
  z-index: -1;
  position: absolute;
  top: 0px;
  width: 79%;
  right: -80%;
}

.consentform-clients-rights-main-div {
  width: 75%;
}

.consentform-clients-points-div {
  font-size: large;
  letter-spacing: 1px;
  background-color: #6c5138;
  color: white;
  padding: 30px;
  border-radius: 25px;
  font-weight: 500;
}

.consentform-acknowledge-para {
  font-size: large;
  letter-spacing: 1px;
  width: 80%;
  font-weight: 500;
}

.consentform-acknowledge-div {
  width: 74%;
}
.consentform-acknowledge-checkbox {
  width: 74%;
  display: flex;
  align-items: center;
}

.consentform-i-accept-this-text {
  font-size: large;
  letter-spacing: 1px;
  font-weight: 500;
}

.consentform-bottom-checkbox {
  width: 22px;
  box-shadow: 2px 2px 5px black;
  height: 22px;
  margin-right: 10px;
}

.consentform-next-button {
  font-size: x-large;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  padding: 5px 25px;
  color: white;
  border-radius: 30px;
  margin-right: 12%;
  background-color: var(--primary-color-text);
  box-shadow: 0px 0px 5px gray;
}

@media screen and (max-width: 425px) {
 .consentform-main-logo-div{
  height: 11%;
 }
.consent-tend-text{
  font-weight: 700;
    font-size: medium;
    top: 1%;
}
.consent-main-counselling-text{
  font-size: small;
  top: 4%;
}
.consentform-main-lalitha-text{
  font-size: 16px;
  top: 10%;
  left: 34%;
}

.consentform-main-counselling-text{
  font-size: 13px;
  top: 13.5%;
  left: 32%;
}

.consentform-main-marital-text{
  font-size: 13px;
  top: 16.5%;
  left: 20%;

}
.consent-main-phone-icon{
width: 20px;
height: 20px;
padding: 3px;
}

.consentform-mobile-span{
 
  font-size: 12px;
  margin-right: 10px !important;
  
}

.consentform-mail-span{
  font-size: 12px;
  

}

.consentform-insta-para{
  font-size: 12px;
  top: 26%;
  left: 35%;
}
.consentform-lady-img{
  top: 28%;
  left: 9%;
  width: 85%;
}




.consentform-phone-email-div{
  top: 21%;
  left: 20%;

}
.consentform-main-img{
  height: 52vh;
}

.consentform-main-img-div{
  height: 65vh;
}

.consent-form-informed-consent-div{
  width: 80%;
  margin-top: 30px;
  text-align: center;
  padding-left: 0px;
}
.consent-form-informed-consent-text{
font-size: x-large;
}
.consent-form-iam-text{
  font-size: 14px;
}
.consentform-clients-rights-main-div{
  text-align: center;
}
.consentform-clients-points-div{
  font-size: 14px;
  text-align: start;
}
.consentform-clients-rights-main-div{
  width: 80%;
}
.consentform-acknowledge-div{
width: 80%;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;

}
.consentform-acknowledge-para{
  width: 100%;
  font-size: 14px;
}
.consentform-bottom-checkbox {
  width: 18px;
  box-shadow: 0px 0px 3px black;
  height: 14px;
}
.consentform-i-accept-this-text{
  font-size: 14px;
}
.consentform-next-button{
  font-size: large;
margin-top: 30px;
margin-right:0%;
}
.consentform-button-div{
  justify-content: center !important;

}

/* fee page */

}
